import React, {useEffect, useState} from 'react';
import {Field, Form} from "react-final-form";
import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMinus, faPlus, faSearch, faArrowRight} from "@fortawesome/free-solid-svg-icons";
import {useSelector} from "react-redux";
import axios from "axios";
import arrayMutators from 'final-form-arrays'
import {FieldArray} from 'react-final-form-arrays'
import {
    Input,
    Label,
    RoundSimpleButton,
    Select,
    Switch,
    TopFormWrapper,
    VariantLabel
} from "../../components/Form";
import {Button} from "../../components/Buttons";
import {apiUrl} from "../api";
import {toast} from "react-toastify";
import {Status} from "../../components/Status";
import {Files} from "../../components/Files";
import {createFormData} from "../../helpers/form";
import {getDataFromGus} from "../../helpers/gus";
import {Comment} from "../Comment/Comment";
import {Modal} from "../../components/Modal";

const InputWrapper = styled.div`
    display: grid;
    grid-template-columns: 160px 320px;
    align-items: center;
    margin-bottom: 10px;
`;

const InputWrapperWithoutLabel = styled(InputWrapper)`
    grid-template-columns: 200px;
    align-items: center;
    text-align: center;
`;

const InputWrapperWithoutLabelWithButton = styled(InputWrapper)`
    grid-template-columns: 170px 30px;

    button {
        padding: 4px 4px;
    }
`;

const NIPWrapper = styled(InputWrapper)`
    grid-template-columns: 160px 290px 30px;

    button {
        padding: 4px 4px;
    }
`;

const AddressWrapper = styled.div`
    margin: 10px;
`;

const StyledForm = styled.form`
    display: flex;
    flex-direction: column;
    position: relative;
`;

const FormContent = styled.div`
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    position: relative;

    & > div {
        margin-right: 20px;
    }
`;

const ButtonsWrapper = styled.div`
    position: absolute;
    top: -5px;
    left: -5px;
`;

const StyledDivError = styled.div`
    color: red;
`;

const FieldSet = styled.fieldset`
    border: none;
`;

const StyledSearchWrapper = styled.div`
    width: 300px;
    display: flex;
`;

const StyledVehicleList = styled.div`
    margin: 10px auto;

    span {
        margin-right: 10px;
    }
`;

const SearchListButton = styled(Button)`
    margin: 20px 0px 10px 160px;
    width: 320px;
`;

const calcDiscount = (priceGross, listPrice, form, field) => {
    if (priceGross && listPrice) {
        form.mutators.setValue(field, Math.abs(((priceGross / listPrice - 1) * 100)).toFixed(0))
    }
}

export const CarTab = ({toDoId, archived}) => {
    const token = useSelector((store) => store.token);
    const [isEnabled, setIsEnabled] = useState(false);
    const [isStorageFlag, setIsStorageFlag] = useState(false);
    const [vehicles, setVehicles] = useState([
        {
            selected: false,
        }
    ]);
    const [allVehicles, setAllVehicles] = useState([]);
    const [files, setFiles] = useState([]);
    const [globalFiles, setGlobalFiles] = useState([]);
    const [clientName, setClientName] = useState(null);
    const [slack, setSlack] = useState(null);
    const [status, setStatus] = useState({});
    const [statuses, setStatuses] = useState([]);
    const [address, setAddress] = useState([]);
    const [isRenew, setIsRenew] = useState(false);
    const [modal, setModal] = useState(false);

    let timeout = 0;

    const getVehicles = (search = null) => {
        axios.get(`${apiUrl}/search/vehicle`, {
            params: {
                name: search,
            },
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then(({data: {vehicles}}) => {
            setAllVehicles(vehicles);
        });
    }

    const search = (e) => {
        let searchText = e.target.value;
        if (timeout) clearTimeout(timeout);
        timeout = setTimeout(() => {
            getVehicles(searchText);
        }, 500);
    }

    const resetFiles = (newVehicles) => {
        let allFiles = [];
        newVehicles.forEach(({attachmentUrl}, index) => {
            allFiles.push({
                index: index,
                name: null,
                file: null,
                url: attachmentUrl
            })
        })
        setFiles(allFiles);
    }

    const calculateEur = (newVehicles) => {
        const todoVehicles = [];

        newVehicles.forEach(newVehicle => {
            const todoVehicle = newVehicle;

            if (newVehicle.rate > 0) {
                todoVehicle.net_eur = (todoVehicle.net / newVehicle.rate).toFixed(2);
                todoVehicle.gross_eur = (todoVehicle.gross / newVehicle.rate).toFixed(2);
            }

            todoVehicles.push(todoVehicle);
        });

        return todoVehicles;
    }

    const validate = (formsData) => {
        const errors = {vehicles: []}

        if (formsData.isStorage == null) {
            errors.isStorage = 'Pole wymagane';
        }

        formsData.vehicles.forEach((values, key) => {
            errors.vehicles[key] = {}
            if (!values.source) {
                errors.vehicles[key].source = 'Wybierz pochodzenie'
            }
            if (!values.item) {
                errors.vehicles[key].item = 'Wybierz rodzaj przedmiotu'
            }
            if (!values.condition) {
                errors.vehicles[key].condition = 'Wybierz stan'
            }
            if (!values.mark) {
                errors.vehicles[key].mark = 'Uzupełnij markę'
            }
            if (!values.model) {
                errors.vehicles[key].model = 'Uzupełnij model'
            }
            if (!values.yearbook) {
                errors.vehicles[key].yearbook = 'Uzupełnij rocznik'
            }
        });

        return errors;
    }

    useEffect(() => {
        loadData();
    }, [token, toDoId]);

    const loadData = () => {
        if (toDoId !== "dodaj") {
            axios.get(`${apiUrl}/toDos/${toDoId}/vehicles`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            }).then(({
                         data: {
                             vehicles: newVehicles,
                             isActive,
                             clientFullName,
                             statuses: newStatuses,
                             vehicle_status: newStatus,
                             isDuplicated,
                             isStorage,
                             slack
                         }
                     }) => {
                setIsEnabled(isActive);
                setClientName(clientFullName);
                setIsRenew(isDuplicated);
                setSlack(slack);
                setIsStorageFlag(isStorage);

                if (isActive) {
                    setVehicles(calculateEur(newVehicles));
                }
                setStatus(newStatus);
                setStatuses(newStatuses);
                resetFiles(newVehicles);
            });

            getVehicles(null);
        }
    }

    const setFilesToForm = (files, index) => {
        const obj = {
            index,
            files
        }
        // eslint-disable-next-line eqeqeq
        if (globalFiles.some(e => e.index == index)) {
            let newArr = [...globalFiles].map((item) => {
                // eslint-disable-next-line eqeqeq
                if (item.index == index) {
                    return obj;
                }
                return item;
            });
            setGlobalFiles(newArr);
        } else {
            setGlobalFiles(prevState => [...prevState, obj]);
        }
    }

    return (
        <>
            <Switch checked={isEnabled} onChange={() => (setIsEnabled(!isEnabled))}/>
            <TopFormWrapper>
                <div>
                    <h2>Dodaj Samochód</h2>
                </div>
                <div>
                    {clientName && (
                        clientName.map((item, key) => (
                            <h2 key={key}>Dla {item}</h2>
                        ))
                    )}

                    <div class="links">
                        <h2>Slack:</h2>
                        {slack && slack['vehicleSlackLink'] && (
                            <a href={slack['vehicleSlackLink']} target="_blank">
                                <FontAwesomeIcon icon={faArrowRight} color="black" fixedWidth/>
                                Samochód
                            </a>
                        )}

                        {slack && slack['policySlackLink'] && (
                            <a href={slack['policySlackLink']} target="_blank">
                                <FontAwesomeIcon icon={faArrowRight} color="black" fixedWidth/>
                                Polisa
                            </a>
                        )}
                    </div>
                </div>
            </TopFormWrapper>
            {isEnabled && <>
                <Status
                    todoId={toDoId}
                    initialValues={{vehicle_status: status}}
                    nameField="vehicle_status"
                    statuses={statuses}
                    disabled={archived || isRenew}
                />
                <Form
                    mutators={{
                        setValue: ([field, value], state, {changeValue}) => {
                            changeValue(state, field, () => value)
                        },
                        // potentially other mutators could be merged here
                        ...arrayMutators
                    }}
                    validate={values => validate(values)}
                    onSubmit={values => {
                        const fd = new FormData();
                        fd.append('isStorage', values.isStorage);
                        createFormData(fd, 'vehicles', values.vehicles);
                        if (files.length > 0) {
                            files.forEach(({name, file}) => {
                                fd.append(`${name}[attachment]`, file);
                            });
                        }

                        if (globalFiles.length > 0) {
                            globalFiles.forEach(({index, files}) => {
                                files.forEach((file) => {
                                    fd.append(`vehicles[${index}][files][${file.index}][file]`, file.file);
                                })
                            });
                        }
                        axios.post(`${apiUrl}/toDos/${toDoId}/vehicles`, fd, {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            }
                        }).then(({data: {vehicles: newVehicles, isActive, isStorage}}) => {
                            setIsEnabled(isActive);
                            setIsStorageFlag(isStorage);

                            if (isActive) {
                                setVehicles(calculateEur(newVehicles));
                            }
                            resetFiles(newVehicles);
                            toast.success("Dane zostały zapisane.");
                        }).catch(() => {
                            toast.error("Popraw dane");
                        });
                    }}
                    initialValues={{
                        vehicles: vehicles, isStorage: isStorageFlag
                    }}
                    render={({handleSubmit, form, values, initialValues}) => (
                        <StyledForm onSubmit={handleSubmit}>

                            <Field
                                name="isStorage"
                                render={({input, meta}) => (
                                    <InputWrapper>
                                        <Label htmlFor={input.name}>Magazyn</Label>
                                        <Select {...input} id={input.name}>
                                            <option value=''>Wybierz..</option>
                                            <option value="1">Tak</option>
                                            <option value="0">Nie</option>
                                        </Select>
                                        {meta.error && meta.touched &&
                                            <StyledDivError>{meta.error}</StyledDivError>}
                                    </InputWrapper>
                                )}/>

                            <SearchListButton inactive disabled={archived} onClick={() => {
                                setModal(true);
                            }}>Wybierz samochód z bazy</SearchListButton>

                            <FormContent>
                                <FieldArray name="vehicles">
                                    {({fields}) => (
                                        <>
                                            <ButtonsWrapper>
                                                <RoundSimpleButton
                                                    disabled={isRenew}
                                                    type="button"
                                                    onClick={() => fields.push({selected: false,})}>
                                                    <FontAwesomeIcon icon={faPlus} color="white"/>
                                                </RoundSimpleButton>
                                                <RoundSimpleButton
                                                    disabled={isRenew}
                                                    type="button"
                                                    onClick={() => fields.pop()}>
                                                    <FontAwesomeIcon icon={faMinus} color="white"/>
                                                </RoundSimpleButton>
                                            </ButtonsWrapper>
                                            {fields.map((name, index) => index !== 0 ? (
                                                    <div key={index}>
                                                        <FieldSet disabled={isRenew}>
                                                            <Field
                                                                name={`${name}.id`}
                                                                type="hidden"
                                                                render={({input, meta}) => (
                                                                    <Input {...input} id={input.name} placeholder=""/>
                                                                )}
                                                            />
                                                            <Field
                                                                name={`${name}.variantNumber`}
                                                                type="hidden"
                                                                initialValue={index + 1}
                                                                render={({input, meta}) => (
                                                                    <>
                                                                        <Input {...input} id={input.name} placeholder=""/>
                                                                    </>
                                                                )}
                                                            />
                                                            <Field
                                                                name={`${name}.selected`}
                                                                type="checkbox"
                                                                render={({input, meta}) => (
                                                                    <>
                                                                        <InputWrapperWithoutLabel>
                                                                            <VariantLabel>
                                                                                Wariant {index + 1} &nbsp;
                                                                                <Input {...input} id={input.name}
                                                                                       placeholder=""/>
                                                                            </VariantLabel>
                                                                        </InputWrapperWithoutLabel>
                                                                    </>
                                                                )}/>
                                                            <Field
                                                                name={`${name}.source`}
                                                                render={({input, meta}) => (
                                                                    <>
                                                                        <InputWrapperWithoutLabel>
                                                                            <Select {...input} id={input.name}>
                                                                                <option value="">Pochodzenie</option>
                                                                                <option value="Poszukiwanie">Poszukiwanie
                                                                                </option>
                                                                                <option value="Nex1 Stock">Nex1 Stock
                                                                                </option>
                                                                                <option value="Nex1 Produkcja">Nex1
                                                                                    Produkcja
                                                                                </option>
                                                                                <option value="Obcy">Obcy</option>
                                                                            </Select>
                                                                            {meta.error && meta.touched &&
                                                                                <StyledDivError>{meta.error}</StyledDivError>}
                                                                        </InputWrapperWithoutLabel>
                                                                    </>
                                                                )}/>
                                                            <Field
                                                                name={`${name}.estimatedDeliveryDate`}
                                                                type="date"
                                                                parse={value => (value === "" ? null : value)}
                                                                render={({input, meta}) => (
                                                                    <>
                                                                        <InputWrapperWithoutLabel>
                                                                            <Input {...input} id={input.name}
                                                                                   placeholder=""/>
                                                                        </InputWrapperWithoutLabel>
                                                                    </>
                                                                )}/>
                                                            <Field
                                                                name={`${name}.item`}
                                                                render={({input, meta}) => (
                                                                    <>
                                                                        <InputWrapperWithoutLabel>
                                                                            <Select {...input} id={input.name}>
                                                                                <option value="">Rodzaj przedmiotu</option>
                                                                                <option value="Samochód osobowy">Samochód
                                                                                    osobowy
                                                                                </option>
                                                                                <option
                                                                                    value="Samochód ciężarowy do 3,5 t">Samochód
                                                                                    ciężarowy do
                                                                                    3,5 t
                                                                                </option>
                                                                                <option
                                                                                    value="Samochód ciężarowy powyżej 3,5 t">Samochód
                                                                                    ciężarowy
                                                                                    powyżej 3,5 t
                                                                                </option>
                                                                                <option value="Ciągnik samochodowy">Ciągnik
                                                                                    samochodowy
                                                                                </option>
                                                                                <option value="Naczepa">Naczepa</option>
                                                                                <option value="Przyczepa ">Przyczepa
                                                                                </option>
                                                                                <option value="Maszyna">Maszyna</option>
                                                                                <option value="Motocykl">Motocykl</option>
                                                                                <option value="Inne">Inne</option>
                                                                            </Select>
                                                                            {meta.error && meta.touched &&
                                                                                <StyledDivError>{meta.error}</StyledDivError>}
                                                                        </InputWrapperWithoutLabel>
                                                                    </>
                                                                )}/>
                                                            <Field
                                                                name={`${name}.condition`}
                                                                render={({input, meta}) => (
                                                                    <>
                                                                        <InputWrapperWithoutLabel>
                                                                            <Select {...input} id={input.name}>
                                                                                <option value={null}>Stan</option>
                                                                                <option value="Nowy">Nowy</option>
                                                                                <option value="Nowy zarejestrowany">Nowy
                                                                                    zarejestrowany
                                                                                </option>
                                                                                <option value="Używany">Używany</option>
                                                                            </Select>
                                                                            {meta.error && meta.touched &&
                                                                                <StyledDivError>{meta.error}</StyledDivError>}
                                                                        </InputWrapperWithoutLabel>
                                                                    </>
                                                                )}/>
                                                            <Field
                                                                name={`${name}.mark`}
                                                                parse={value => (value === "" ? null : value)}
                                                                render={({input, meta}) => (
                                                                    <>
                                                                        <InputWrapperWithoutLabel>
                                                                            <Input {...input} id={input.name}
                                                                                   placeholder=""/>
                                                                            {meta.error && meta.touched &&
                                                                                <StyledDivError>{meta.error}</StyledDivError>}
                                                                        </InputWrapperWithoutLabel>
                                                                    </>
                                                                )}/>
                                                            <Field
                                                                name={`${name}.model`}
                                                                parse={value => (value === "" ? null : value)}
                                                                render={({input, meta}) => (
                                                                    <>
                                                                        <InputWrapperWithoutLabel>
                                                                            <Input {...input} id={input.name}
                                                                                   placeholder=""/>
                                                                            {meta.error && meta.touched &&
                                                                                <StyledDivError>{meta.error}</StyledDivError>}
                                                                        </InputWrapperWithoutLabel>
                                                                    </>
                                                                )}/>
                                                            <Field
                                                                name={`${name}.capacity`}
                                                                parse={value => (value === "" ? null : value)}
                                                                render={({input, meta}) => (
                                                                    <>
                                                                        <InputWrapperWithoutLabel>
                                                                            <Input {...input} id={input.name}
                                                                                   placeholder=""/>
                                                                        </InputWrapperWithoutLabel>
                                                                    </>
                                                                )}/>
                                                            <Field
                                                                name={`${name}.yearbook`}
                                                                parse={value => (value === "" ? null : value)}
                                                                render={({input, meta}) => (
                                                                    <>
                                                                        <InputWrapperWithoutLabel>
                                                                            <Input {...input} id={input.name}
                                                                                   placeholder=""/>
                                                                            {meta.error && meta.touched &&
                                                                                <StyledDivError>{meta.error}</StyledDivError>}
                                                                        </InputWrapperWithoutLabel>
                                                                    </>
                                                                )}/>
                                                            <Field
                                                                name={`${name}.firstRegistration`}
                                                                type="date"
                                                                initialValue={null}
                                                                parse={value => (value === "" ? null : value)}
                                                                render={({input, meta}) => (
                                                                    <>
                                                                        <InputWrapperWithoutLabel>
                                                                            <Input {...input} id={input.name}
                                                                                   placeholder=""/>
                                                                            {meta.error && meta.touched &&
                                                                                <StyledDivError>{meta.error}</StyledDivError>}
                                                                        </InputWrapperWithoutLabel>
                                                                    </>
                                                                )}/>
                                                            <Field
                                                                name={`${name}.registrationNumber`}
                                                                parse={value => (value === "" ? null : value)}
                                                                render={({input, meta}) => (
                                                                    <>
                                                                        <InputWrapperWithoutLabel>
                                                                            <Input {...input} id={input.name}
                                                                                   placeholder=""/>
                                                                        </InputWrapperWithoutLabel>
                                                                    </>
                                                                )}/>
                                                            <Field
                                                                name={`${name}.vin`}
                                                                parse={value => (value === "" ? null : value)}
                                                                render={({input, meta}) => (
                                                                    <>
                                                                        <InputWrapperWithoutLabel>
                                                                            <Input {...input} id={input.name}
                                                                                   placeholder=""/>
                                                                        </InputWrapperWithoutLabel>
                                                                    </>
                                                                )}/>
                                                            <Field
                                                                name={`${name}.equipmentVersion`}
                                                                parse={value => (value === "" ? null : value)}
                                                                render={({input, meta}) => (
                                                                    <>
                                                                        <InputWrapperWithoutLabel>
                                                                            <Input {...input} id={input.name}
                                                                                   placeholder=""/>
                                                                        </InputWrapperWithoutLabel>
                                                                    </>
                                                                )}/>
                                                            <Field
                                                                name={`${name}.mileage`}
                                                                parse={value => (value === "" ? null : value)}
                                                                render={({input, meta}) => (
                                                                    <>
                                                                        <InputWrapperWithoutLabel>
                                                                            <Input {...input} id={input.name}
                                                                                   placeholder=""/>
                                                                        </InputWrapperWithoutLabel>
                                                                    </>
                                                                )}/>
                                                            <Field
                                                                name={`${name}.salesDocument`}
                                                                render={({input, meta}) => (
                                                                    <>
                                                                        <InputWrapperWithoutLabel>
                                                                            <Select {...input} id={input.name}>
                                                                                <option value="">Dokument sprzedaży</option>
                                                                                <option value="FV VAT23%">FV VAT23%</option>
                                                                                <option value="FV Marża">FV Marża</option>
                                                                                <option value="Umowa sprzedaży">Umowa
                                                                                    sprzedaży
                                                                                </option>
                                                                                <option value="Inny">Inny</option>
                                                                            </Select>
                                                                        </InputWrapperWithoutLabel>
                                                                    </>
                                                                )}/>
                                                            <Field
                                                                name={`${name}.listPrice`}
                                                                type="number"
                                                                min="0"
                                                                parse={value => (value === "" ? null : value)}
                                                                render={({input, meta}) => (
                                                                    <>
                                                                        <InputWrapperWithoutLabel>
                                                                            <Input {...input} id={input.name} placeholder=""
                                                                                   step="0.01" onChange={(e) => {
                                                                                input.onChange(e);
                                                                                calcDiscount(values.vehicles[index].gross, parseFloat(e.target.value), form, `${name}.discount`);
                                                                            }}/>
                                                                        </InputWrapperWithoutLabel>
                                                                    </>
                                                                )}/>
                                                            <Field
                                                                name={`${name}.rate`}
                                                                type="number"
                                                                min="0"
                                                                parse={value => (value === "" ? null : value)}
                                                                render={({input, meta}) => (
                                                                    <>
                                                                        <InputWrapperWithoutLabel>
                                                                            <Input {...input} id={input.name} placeholder=""
                                                                                   onChange={(e) => {
                                                                                       input.onChange(e);
                                                                                       const rate = parseFloat(e.target.value);
                                                                                       form.mutators.setValue(`${name}.net_eur`,
                                                                                           rate > 0 ? (parseFloat(values.vehicles[index].net) / rate).toFixed(2) : null);
                                                                                       form.mutators.setValue(`${name}.gross_eur`,
                                                                                           rate > 0 ? (parseFloat(values.vehicles[index].gross) / rate).toFixed(2) : null);
                                                                                   }}
                                                                                   step="0.01"/>
                                                                        </InputWrapperWithoutLabel>
                                                                    </>
                                                                )}/>
                                                            <Field
                                                                name={`${name}.net`}
                                                                type="number"
                                                                min="0"
                                                                parse={value => (value === "" ? null : value)}
                                                                render={({input, meta}) => (
                                                                    <>
                                                                        <InputWrapperWithoutLabel>
                                                                            <Input {...input} id={input.name} placeholder=""
                                                                                   onChange={(e) => {
                                                                                       input.onChange(e);

                                                                                       const eurValue = parseFloat(values.vehicles[index].rate) > 0 ?
                                                                                           parseFloat(e.target.value) / parseFloat(values.vehicles[index].rate) : 0;
                                                                                       form.mutators.setValue(`${name}.net_eur`, eurValue.toFixed(2));

                                                                                       const netValue = parseFloat(e.target.value);

                                                                                       if (values.vehicles[index].salesDocument === 'FV VAT23%') {
                                                                                           form.mutators.setValue(`${name}.gross`, (netValue * 1.23).toFixed(2));
                                                                                           form.mutators.setValue(`${name}.gross_eur`, (eurValue * 1.23).toFixed(2));
                                                                                       } else {
                                                                                           form.mutators.setValue(`${name}.gross`, netValue.toFixed(2));
                                                                                           form.mutators.setValue(`${name}.gross_eur`, eurValue.toFixed(2));
                                                                                       }
                                                                                   }}
                                                                                   step="0.01"/>
                                                                        </InputWrapperWithoutLabel>
                                                                    </>
                                                                )}/>
                                                            <Field
                                                                name={`${name}.net_eur`}
                                                                type="number"
                                                                parse={value => (value === "" ? null : value)}
                                                                render={({input, meta}) => (
                                                                    <>
                                                                        <InputWrapperWithoutLabel>
                                                                            <Input {...input} id={input.name} placeholder=""
                                                                                   disabled/>
                                                                        </InputWrapperWithoutLabel>
                                                                    </>
                                                                )}/>
                                                            <Field
                                                                name={`${name}.gross`}
                                                                type="number"
                                                                min="0"
                                                                parse={value => (value === "" ? null : value)}
                                                                render={({input, meta}) => (
                                                                    <>
                                                                        <InputWrapperWithoutLabel>
                                                                            <Input {...input} id={input.name} placeholder=""
                                                                                   onChange={(e) => {
                                                                                       input.onChange(e);
                                                                                       const grossValue = parseFloat(e.target.value);

                                                                                       const grossEurValue = parseFloat(values.vehicles[index].rate) > 0 ?
                                                                                           parseFloat(e.target.value) / parseFloat(values.vehicles[index].rate) : 0;

                                                                                       const netValue = grossValue - grossValue * 0.23;
                                                                                       const netEurValue = grossEurValue - grossEurValue * 0.23;

                                                                                       form.mutators.setValue(`${name}.gross_eur`, grossEurValue.toFixed(2));

                                                                                       if (values.vehicles[index].salesDocument === 'FV VAT23%') {
                                                                                           form.mutators.setValue(`${name}.net`, netValue.toFixed(2));
                                                                                           form.mutators.setValue(`${name}.net_eur`, netEurValue.toFixed(2));
                                                                                       } else {
                                                                                           form.mutators.setValue(`${name}.net`, grossValue.toFixed(2));
                                                                                           form.mutators.setValue(`${name}.net_eur`, grossEurValue.toFixed(2));
                                                                                       }
                                                                                       calcDiscount(parseFloat(e.target.value), values.vehicles[index].listPrice, form, `${name}.discount`);
                                                                                   }}
                                                                                   step="0.01"/>
                                                                        </InputWrapperWithoutLabel>
                                                                    </>
                                                                )}/>
                                                            <Field
                                                                name={`${name}.gross_eur`}
                                                                type="number"
                                                                parse={value => (value === "" ? null : value)}
                                                                render={({input, meta}) => (
                                                                    <>
                                                                        <InputWrapperWithoutLabel>
                                                                            <Input {...input} id={input.name} placeholder=""
                                                                                   disabled/>
                                                                        </InputWrapperWithoutLabel>
                                                                    </>
                                                                )}/>
                                                            <Field
                                                                name={`${name}.discount`}
                                                                type="number"
                                                                min="0"
                                                                parse={value => (value === "" ? null : value)}
                                                                render={({input, meta}) => (
                                                                    <>
                                                                        <InputWrapperWithoutLabel>
                                                                            <Input {...input} id={input.name} placeholder=""
                                                                                   step="0.01"/>
                                                                        </InputWrapperWithoutLabel>
                                                                    </>
                                                                )}/>
                                                            <Field
                                                                name={`${name}.provider`}
                                                                parse={value => (value === "" ? null : value)}
                                                                render={({input, meta}) => (
                                                                    <>
                                                                        <InputWrapperWithoutLabel>
                                                                            <Input {...input} id={input.name}
                                                                                   placeholder=""/>
                                                                        </InputWrapperWithoutLabel>
                                                                    </>
                                                                )}/>
                                                            <Field
                                                                name={`${name}.nip`}
                                                                parse={value => (value === "" ? null : value)}
                                                                render={({input, meta}) => (
                                                                    <>
                                                                        <InputWrapperWithoutLabelWithButton>
                                                                            <Input {...input} id={input.name}
                                                                                   placeholder=""/>
                                                                            <Button type="button" inactive small
                                                                                    onClick={() => {
                                                                                        if (values.vehicles[index].nip) {
                                                                                            getDataFromGus(values.vehicles[index].nip, token, (data) => {
                                                                                                form.mutators.setValue(`${name}.provider`, data.name)
                                                                                                setAddress(`${data.street} ${data.propertyNumber}, ${data.city}, ${data.zipCode}`)
                                                                                            });
                                                                                        } else {
                                                                                            toast.error("Prosze uzupełnij NIP.");
                                                                                        }
                                                                                    }}><FontAwesomeIcon icon={faSearch}
                                                                                                        color="white"/></Button>
                                                                        </InputWrapperWithoutLabelWithButton>
                                                                    </>
                                                                )}/>

                                                            <Files name={`${name}.files`} index={index}
                                                                   setFilesToForm={setFilesToForm} disabled={isRenew}/>
                                                        </FieldSet>
                                                    </div>
                                                ) : (
                                                    <div key={index}>
                                                        <FieldSet disabled={isRenew}>
                                                            <Field
                                                                name={`${name}.id`}
                                                                type="hidden"
                                                                render={({input, meta}) => (
                                                                    <Input {...input} id={input.name} placeholder=""/>
                                                                )}
                                                            />
                                                            <Field
                                                                name={`${name}.variantNumber`}
                                                                type="hidden"
                                                                initialValue={index + 1}
                                                                render={({input, meta}) => (
                                                                    <>
                                                                        <Input {...input} id={input.name} placeholder=""/>
                                                                    </>
                                                                )}
                                                            />
                                                            <Field
                                                                name={`${name}.selected`}
                                                                type="checkbox"
                                                                render={({input, meta}) => (
                                                                    <>
                                                                        <InputWrapperWithoutLabel>
                                                                            <VariantLabel>
                                                                                Wariant 1 &nbsp;
                                                                                <Input {...input} id={input.name}
                                                                                       placeholder=""/>
                                                                            </VariantLabel>
                                                                        </InputWrapperWithoutLabel>
                                                                    </>
                                                                )}/>
                                                            <Field
                                                                name={`${name}.source`}
                                                                render={({input, meta}) => (
                                                                    <>
                                                                        <InputWrapper>
                                                                            <Label htmlFor={input.name}>Pochodzenie*</Label>
                                                                            <Select {...input} id={input.name}>
                                                                                <option value="">Pochodzenie</option>
                                                                                <option value="Poszukiwanie">Poszukiwanie
                                                                                </option>
                                                                                <option value="Nex1 Stock">Nex1 Stock
                                                                                </option>
                                                                                <option value="Nex1 Produkcja">Nex1
                                                                                    Produkcja
                                                                                </option>
                                                                                <option value="Obcy">Obcy</option>
                                                                            </Select>
                                                                            {meta.error && meta.touched &&
                                                                                <StyledDivError>{meta.error}</StyledDivError>}
                                                                        </InputWrapper>
                                                                    </>
                                                                )}/>
                                                            <Field
                                                                name={`${name}.estimatedDeliveryDate`}
                                                                type="date"
                                                                parse={value => (value === "" ? null : value)}
                                                                render={({input, meta}) => (
                                                                    <>
                                                                        <InputWrapper>
                                                                            <Label htmlFor={input.name}>Prognozowana data
                                                                                dostawy</Label>
                                                                            <Input {...input} id={input.name}
                                                                                   placeholder=""/>
                                                                        </InputWrapper>
                                                                    </>
                                                                )}/>
                                                            <Field
                                                                name={`${name}.item`}
                                                                render={({input, meta}) => (
                                                                    <>
                                                                        <InputWrapper>
                                                                            <Label htmlFor={input.name}>Rodzaj
                                                                                przedmiotu*</Label>
                                                                            <Select {...input} id={input.name}>
                                                                                <option value="">Rodzaj przedmiotu</option>
                                                                                <option value="Samochód osobowy">Samochód
                                                                                    osobowy
                                                                                </option>
                                                                                <option
                                                                                    value="Samochód ciężarowy do 3,5 t">Samochód
                                                                                    ciężarowy do
                                                                                    3,5 t
                                                                                </option>
                                                                                <option
                                                                                    value="Samochód ciężarowy powyżej 3,5 t">Samochód
                                                                                    ciężarowy
                                                                                    powyżej 3,5 t
                                                                                </option>
                                                                                <option value="Ciągnik samochodowy">Ciągnik
                                                                                    samochodowy
                                                                                </option>
                                                                                <option value="Naczepa">Naczepa</option>
                                                                                <option value="Przyczepa ">Przyczepa
                                                                                </option>
                                                                                <option value="Maszyna">Maszyna</option>
                                                                                <option value="Motocykl">Motocykl</option>
                                                                                <option value="Inne">Inne</option>
                                                                            </Select>
                                                                            {meta.error && meta.touched &&
                                                                                <StyledDivError>{meta.error}</StyledDivError>}
                                                                        </InputWrapper>
                                                                    </>
                                                                )}/>
                                                            <Field
                                                                name={`${name}.condition`}
                                                                render={({input, meta}) => (
                                                                    <>
                                                                        <InputWrapper>
                                                                            <Label htmlFor={input.name}>Stan*</Label>
                                                                            <Select {...input} id={input.name}>
                                                                                <option value="">Stan</option>
                                                                                <option value="Nowy">Nowy</option>
                                                                                <option value="Nowy zarejestrowany">Nowy
                                                                                    zarejestrowany
                                                                                </option>
                                                                                <option value="Używany">Używany</option>
                                                                            </Select>
                                                                            {meta.error && meta.touched &&
                                                                                <StyledDivError>{meta.error}</StyledDivError>}
                                                                        </InputWrapper>
                                                                    </>
                                                                )}/>
                                                            <Field
                                                                name={`${name}.mark`}
                                                                parse={value => (value === "" ? null : value)}
                                                                render={({input, meta}) => (
                                                                    <>
                                                                        <InputWrapper>
                                                                            <Label htmlFor={input.name}>Marka*</Label>
                                                                            <Input {...input} id={input.name}
                                                                                   placeholder=""/>
                                                                            {meta.error && meta.touched &&
                                                                                <StyledDivError>{meta.error}</StyledDivError>}
                                                                        </InputWrapper>
                                                                    </>
                                                                )}/>
                                                            <Field
                                                                name={`${name}.model`}
                                                                parse={value => (value === "" ? null : value)}
                                                                render={({input, meta}) => (
                                                                    <>
                                                                        <InputWrapper>
                                                                            <Label htmlFor={input.name}>Model*</Label>
                                                                            <Input {...input} id={input.name}
                                                                                   placeholder=""/>
                                                                            {meta.error && meta.touched &&
                                                                                <StyledDivError>{meta.error}</StyledDivError>}
                                                                        </InputWrapper>
                                                                    </>
                                                                )}/>
                                                            <Field
                                                                name={`${name}.capacity`}
                                                                parse={value => (value === "" ? null : value)}
                                                                render={({input, meta}) => (
                                                                    <>
                                                                        <InputWrapper>
                                                                            <Label htmlFor={input.name}>Pojemność</Label>
                                                                            <Input {...input} id={input.name}
                                                                                   placeholder=""/>
                                                                        </InputWrapper>
                                                                    </>
                                                                )}/>
                                                            <Field
                                                                name={`${name}.yearbook`}
                                                                parse={value => (value === "" ? null : value)}
                                                                render={({input, meta}) => (
                                                                    <>
                                                                        <InputWrapper>
                                                                            <Label htmlFor={input.name}>Rocznik*</Label>
                                                                            <Input {...input} id={input.name}
                                                                                   placeholder=""/>
                                                                            {meta.error && meta.touched &&
                                                                                <StyledDivError>{meta.error}</StyledDivError>}
                                                                        </InputWrapper>
                                                                    </>
                                                                )}/>
                                                            <Field
                                                                name={`${name}.firstRegistration`}
                                                                type="date"
                                                                initialValue={null}
                                                                parse={value => (value === "" ? null : value)}
                                                                render={({input, meta}) => (
                                                                    <>
                                                                        <InputWrapper>
                                                                            <Label htmlFor={input.name}>Data 1
                                                                                rejestracji</Label>
                                                                            <Input {...input} id={input.name}
                                                                                   placeholder=""/>
                                                                            {meta.error && meta.touched &&
                                                                                <StyledDivError>{meta.error}</StyledDivError>}
                                                                        </InputWrapper>
                                                                    </>
                                                                )}/>
                                                            <Field
                                                                name={`${name}.registrationNumber`}
                                                                parse={value => (value === "" ? null : value)}
                                                                render={({input, meta}) => (
                                                                    <>
                                                                        <InputWrapper>
                                                                            <Label htmlFor={input.name}>Numer
                                                                                rejestracyjny</Label>
                                                                            <Input {...input} id={input.name}
                                                                                   placeholder=""/>
                                                                        </InputWrapper>
                                                                    </>
                                                                )}/>
                                                            <Field
                                                                name={`${name}.vin`}
                                                                parse={value => (value === "" ? null : value)}
                                                                render={({input, meta}) => (
                                                                    <>
                                                                        <InputWrapper>
                                                                            <Label htmlFor={input.name}>VIN</Label>
                                                                            <Input {...input} id={input.name}
                                                                                   placeholder=""/>
                                                                        </InputWrapper>
                                                                    </>
                                                                )}/>
                                                            <Field
                                                                name={`${name}.equipmentVersion`}
                                                                parse={value => (value === "" ? null : value)}
                                                                render={({input, meta}) => (
                                                                    <>
                                                                        <InputWrapper>
                                                                            <Label htmlFor={input.name}>Wersja
                                                                                wyposażenia</Label>
                                                                            <Input {...input} id={input.name}
                                                                                   placeholder=""/>
                                                                        </InputWrapper>
                                                                    </>
                                                                )}/>
                                                            <Field
                                                                name={`${name}.mileage`}
                                                                parse={value => (value === "" ? null : value)}
                                                                render={({input, meta}) => (
                                                                    <>
                                                                        <InputWrapper>
                                                                            <Label htmlFor={input.name}>Przebieg</Label>
                                                                            <Input {...input} id={input.name}
                                                                                   placeholder=""/>
                                                                        </InputWrapper>
                                                                    </>
                                                                )}/>
                                                            <Field
                                                                name={`${name}.salesDocument`}
                                                                render={({input, meta}) => (
                                                                    <>
                                                                        <InputWrapper>
                                                                            <Label htmlFor={input.name}>Dokument
                                                                                sprzedaży*</Label>
                                                                            <Select {...input} id={input.name}>
                                                                                <option value="">Dokument sprzedaży</option>
                                                                                <option value="FV VAT23%">FV VAT23%</option>
                                                                                <option value="FV Marża">FV Marża</option>
                                                                                <option value="Umowa sprzedaży">Umowa
                                                                                    sprzedaży
                                                                                </option>
                                                                                <option value="Inny">Inny</option>
                                                                            </Select>
                                                                        </InputWrapper>
                                                                    </>
                                                                )}/>
                                                            <Field
                                                                name={`${name}.listPrice`}
                                                                type="number"
                                                                min="0"
                                                                parse={value => (value === "" ? null : value)}
                                                                render={({input, meta}) => (
                                                                    <>
                                                                        <InputWrapper>
                                                                            <Label htmlFor={input.name}>Cena
                                                                                katalogowa</Label>
                                                                            <Input {...input} id={input.name} placeholder=""
                                                                                   onChange={(e) => {
                                                                                       input.onChange(e);
                                                                                       calcDiscount(values.vehicles[index].gross, parseFloat(e.target.value), form, `${name}.discount`);
                                                                                   }}
                                                                                   step="0.01"/>
                                                                        </InputWrapper>
                                                                    </>
                                                                )}/>
                                                            <Field
                                                                name={`${name}.rate`}
                                                                type="number"
                                                                min="0"
                                                                parse={value => (value === "" ? null : value)}
                                                                render={({input, meta}) => (
                                                                    <>
                                                                        <InputWrapper>
                                                                            <Label htmlFor={input.name}>Kurs EUR</Label>
                                                                            <Input {...input} id={input.name} placeholder=""
                                                                                   onChange={(e) => {
                                                                                       input.onChange(e);
                                                                                       const rate = parseFloat(e.target.value);
                                                                                       form.mutators.setValue(`${name}.net_eur`,
                                                                                           rate > 0 ? (parseFloat(values.vehicles[index].net) / rate).toFixed(2) : null);
                                                                                       form.mutators.setValue(`${name}.gross_eur`,
                                                                                           rate > 0 ? (parseFloat(values.vehicles[index].gross) / rate).toFixed(2) : null);
                                                                                   }}
                                                                                   step="0.01"/>
                                                                        </InputWrapper>
                                                                    </>
                                                                )}/>
                                                            <Field
                                                                name={`${name}.net`}
                                                                type="number"
                                                                min="0"
                                                                parse={value => (value === "" ? null : value)}
                                                                render={({input, meta}) => (
                                                                    <>
                                                                        <InputWrapper>
                                                                            <Label htmlFor={input.name}>Cena netto*</Label>
                                                                            <Input {...input} id={input.name} placeholder=""
                                                                                   onChange={(e) => {
                                                                                       input.onChange(e);

                                                                                       const eurValue = parseFloat(values.vehicles[index].rate) > 0 ?
                                                                                           parseFloat(e.target.value) / parseFloat(values.vehicles[index].rate) : 0;
                                                                                       form.mutators.setValue(`${name}.net_eur`, eurValue.toFixed(2));

                                                                                       const netValue = parseFloat(e.target.value);

                                                                                       if (values.vehicles[index].salesDocument === 'FV VAT23%') {
                                                                                           form.mutators.setValue(`${name}.gross`, (netValue * 1.23).toFixed(2));
                                                                                           form.mutators.setValue(`${name}.gross_eur`, (eurValue * 1.23).toFixed(2));
                                                                                       } else {
                                                                                           form.mutators.setValue(`${name}.gross`, netValue.toFixed(2));
                                                                                           form.mutators.setValue(`${name}.gross_eur`, eurValue.toFixed(2));
                                                                                       }
                                                                                   }}
                                                                                   step="0.01"/>
                                                                        </InputWrapper>
                                                                    </>
                                                                )}/>
                                                            <Field
                                                                name={`${name}.net_eur`}
                                                                type="number"
                                                                parse={value => (value === "" ? null : value)}
                                                                render={({input, meta}) => (
                                                                    <>
                                                                        <InputWrapper>
                                                                            <Label htmlFor={input.name}>Cena netto
                                                                                EUR</Label>
                                                                            <Input {...input} id={input.name} placeholder=""
                                                                                   disabled/>
                                                                        </InputWrapper>
                                                                    </>
                                                                )}/>
                                                            <Field
                                                                name={`${name}.gross`}
                                                                type="number"
                                                                min="0"
                                                                parse={value => (value === "" ? null : value)}
                                                                render={({input, meta}) => (
                                                                    <>
                                                                        <InputWrapper>
                                                                            <Label htmlFor={input.name}>Cena brutto*</Label>
                                                                            <Input {...input} id={input.name} placeholder=""
                                                                                   onChange={(e) => {
                                                                                       input.onChange(e);
                                                                                       const grossValue = parseFloat(e.target.value);

                                                                                       const grossEurValue = parseFloat(values.vehicles[index].rate) > 0 ?
                                                                                           parseFloat(e.target.value) / parseFloat(values.vehicles[index].rate) : 0;

                                                                                       const netValue = grossValue / 1.23;
                                                                                       const netEurValue = grossEurValue / 1.23;

                                                                                       form.mutators.setValue(`${name}.gross_eur`, grossEurValue.toFixed(2));

                                                                                       if (values.vehicles[index].salesDocument === 'FV VAT23%') {
                                                                                           form.mutators.setValue(`${name}.net`, netValue.toFixed(2));
                                                                                           form.mutators.setValue(`${name}.net_eur`, netEurValue.toFixed(2));
                                                                                       } else {
                                                                                           form.mutators.setValue(`${name}.net`, grossValue.toFixed(2));
                                                                                           form.mutators.setValue(`${name}.net_eur`, grossEurValue.toFixed(2));
                                                                                       }
                                                                                       calcDiscount(parseFloat(e.target.value), values.vehicles[index].listPrice, form, `${name}.discount`);
                                                                                   }}
                                                                                   step="0.01"/>
                                                                        </InputWrapper>
                                                                    </>
                                                                )}/>
                                                            <Field
                                                                name={`${name}.gross_eur`}
                                                                type="number"
                                                                parse={value => (value === "" ? null : value)}
                                                                render={({input, meta}) => (
                                                                    <>
                                                                        <InputWrapper>
                                                                            <Label htmlFor={input.name}>Cena brutto
                                                                                EUR</Label>
                                                                            <Input {...input} id={input.name} placeholder=""
                                                                                   disabled/>
                                                                        </InputWrapper>
                                                                    </>
                                                                )}/>
                                                            <Field
                                                                name={`${name}.discount`}
                                                                type="number"
                                                                min="0"
                                                                parse={value => (value === "" ? null : value)}
                                                                render={({input, meta}) => (
                                                                    <>
                                                                        <InputWrapper>
                                                                            <Label htmlFor={input.name}>Rabat%</Label>
                                                                            <Input {...input} id={input.name} placeholder=""
                                                                                   step="0.01"/>
                                                                        </InputWrapper>
                                                                    </>
                                                                )}/>
                                                            <Field
                                                                name={`${name}.provider`}
                                                                parse={value => (value === "" ? null : value)}
                                                                render={({input, meta}) => (
                                                                    <>
                                                                        <InputWrapper>
                                                                            <Label htmlFor={input.name}>Dostawca</Label>
                                                                            <Input {...input} id={input.name}
                                                                                   placeholder=""/>
                                                                        </InputWrapper>
                                                                    </>
                                                                )}/>
                                                            <Field
                                                                name={`${name}.nip`}
                                                                parse={value => (value === "" ? null : value)}
                                                                render={({input, meta}) => (
                                                                    <>
                                                                        <NIPWrapper>
                                                                            <Label htmlFor={input.name}>NIP dostawcy</Label>
                                                                            <Input {...input} id={input.name}
                                                                                   placeholder=""/>

                                                                            <Button type="button" inactive small
                                                                                    onClick={() => {
                                                                                        if (values.vehicles[index].nip) {
                                                                                            getDataFromGus(values.vehicles[index].nip, token, (data) => {
                                                                                                form.mutators.setValue(`${name}.provider`, data.name)
                                                                                                setAddress(`${data.street} ${data.propertyNumber}, ${data.city}, ${data.zipCode}`)
                                                                                            });
                                                                                        } else {
                                                                                            toast.error("Prosze uzupełnij NIP.");
                                                                                        }
                                                                                    }}><FontAwesomeIcon icon={faSearch}
                                                                                                        color="white"/></Button>
                                                                        </NIPWrapper>
                                                                        {address && (
                                                                            <AddressWrapper>
                                                                                <span>{address}</span>
                                                                            </AddressWrapper>
                                                                        )}
                                                                    </>
                                                                )}/>

                                                            <Files name={`${name}.files`} index={index}
                                                                   setFilesToForm={setFilesToForm} disabled={isRenew}/>

                                                            {(!archived && !isRenew) && (
                                                                <Button>Zapisz</Button>
                                                            )}
                                                        </FieldSet>
                                                    </div>
                                                )
                                            )}

                                        </>
                                    )}
                                </FieldArray>
                            </FormContent>
                        </StyledForm>
                    )}
                />

                {modal && <Modal closeModal={() => {
                    setModal(false);
                }}>
                    <StyledSearchWrapper>
                        <Input name="search" placeholder="Szukaj" onChange={e => search(e)}/>
                    </StyledSearchWrapper>
                    {allVehicles.map((vehicle) => (
                        <StyledVehicleList>
                        <span><b>{vehicle.mark} {vehicle.model}</b>
                            {vehicle.registrationNumber && (<>,&nbsp;{vehicle.registrationNumber}</>)}
                            {vehicle.vin && (<>,&nbsp;VIN:&nbsp;{vehicle.vin}</>)}
                        </span>
                            <Button inactive small smallText onClick={() => {
                                axios.put(`${apiUrl}/toDo/${toDoId}/selectVehicle`, {
                                    vehicleId: vehicle.id,
                                }, {
                                    headers: {
                                        Authorization: `Bearer ${token}`,
                                    }
                                }).then(() => {
                                    loadData();
                                    toast.success("Wybrano samochód.");
                                    setModal(false);
                                });
                            }}>Wybierz
                            </Button>
                        </StyledVehicleList>
                    ))}

                </Modal>}
            </>}

            <Comment toDoId={toDoId} toDoTab={"vehicle"} vehicleId={null} policyId={null} archived={false}
                     history={false}/>
        </>
    );
};
