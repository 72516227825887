import React, {useEffect, useState} from 'react';
import {
    StyledFontAwesomeIcon,
    StyledFontAwesomeIconWarning,
    StyledLink
} from "../../components/ListComponents";
import {faArrowRight, faCircle, faFileDownload, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import {apiUrl} from "../api";
import styled from "styled-components";
import {Modal} from "../../components/Modal";
import {ModalButtonWrapper, P} from "../../components/Modals";
import {Button} from "../../components/Buttons";
import {toast} from "react-toastify";
import {Link} from "react-router-dom";
import {NEW_TO_DO} from "../../actions";
import {downloadPackage} from "../../helpers/file";
import {List} from "../../components/List";
import moment from "moment";
import {
    financeStatusColor,
    policyOfferStatusColor, policyStatusColor,
    registrationStatusColor,
    vehicleStatusColor
} from "../../data/statusColors";

const StyledButton = styled.button`
    border: none;
    background: transparent;
    color: ${({theme: {colors: {red}}}) => red};;
    font-weight: 900;
    font-size: 1.6rem;
    top: 5px;
    right: 5px;
`;

const NewStatus = styled.span`
    color: red;
`;

export const ToDoListTable = ({archived, category, defaultSort, defaultSortDirection}) => {
    const isCoOwner = useSelector((store) => store.roleName) === 'Współwłaściciel';
    const [toDo, setToDo] = useState([]);
    const token = useSelector((store) => store.token);
    const dispatch = useDispatch();
    const [total, setTotal] = useState(0);
    const [sort, setSort] = useState(defaultSort);
    const [sortDirection, setSortDirection] = useState(defaultSortDirection);
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [searchParam, setSearchParam] = useState("");
    const [openModal, setOpenModal] = useState(null);
    const [filters, setFilters] = useState(null);

    useEffect(() => {
        loadData();
    }, [token, archived, page, rowsPerPage, sort, sortDirection, searchParam, filters])

    useEffect(() => {
        if (openModal === false) {
            loadData();
        }
    }, [openModal])

    const loadData = () => {
        axios.get(`${apiUrl}/toDo`, {
            params: {
                archived: archived,
                category: category,
                page: page,
                rowsPerPage: rowsPerPage,
                sort: sort,
                sortDirection: sortDirection,
                search: searchParam,
                filters: filters
            },
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then(({data: {toDos, total, countToDoWithNewMessages}}) => {
            setToDo(toDos);
            setTotal(total);
            dispatch({
                type: NEW_TO_DO,
                payload: {newToDo: countToDoWithNewMessages}
            });
        });
    }

    const tableColumns = [
        {name: 'ID', property: 'id'},
        {
            name: 'Produkcja',
            group: ['all', 'vehicle', 'finance'],
            filterOnly: true,
            property: 'vehicleProductionStatus',
            type: 'switchFilter'
        },
        {
            name: 'Finansowanie',
            group: ['all', 'vehicle', 'finance'],
            filterOnly: true,
            property: 'financeConsentStatus',
            type: 'switchFilter'
        },
        {name: 'Bez kategorii', group: ['all'], filterOnly: true, property: 'noCategory', type: 'switchFilter'},
        {
            name: 'Oferta',
            group: ['all', 'vehicle', 'finance'],
            filterOnly: true,
            property: 'offerCategory',
            type: 'choiceFilter',
            filterValues: [
                {label: 'Przydział kontaktu', value: 'policyOffer'},
                {label: 'Oferta', value: 'offer'},
                {label: 'Zaakceptowana', value: 'accepted'},
                {label: 'Wsparcie', value: 'support'},
                {label: 'Rezygnacja', value: 'withdraw'},
            ]
        },

        // ALL, VEHICLE, FINANCE
        {
            name: 'Imię i nazwisko/Firma',
            property: 'name',
            relationProperty: 'value',
            group: ['all', 'vehicle', 'finance'],
            columnPage: [1, 2, 3]
        },
        {name: 'Marka', property: 'vehicleMark', group: ['all', 'vehicle', 'finance'], columnPage: [1, 2, 3]},
        {name: 'Model', property: 'vehicleModel', group: ['all', 'vehicle', 'finance'], columnPage: [1, 2, 3]},
        {name: 'Cena', property: 'vehiclePriceGross', group: ['all', 'vehicle', 'finance'], columnPage: [1, 2, 3]},
        {name: 'VIN', property: 'vin', group: ['all', 'vehicle', 'finance'], columnPage: [1]},
        {name: 'Finansujący', property: 'institution', group: ['all', 'vehicle', 'finance'], columnPage: [1]},
        {name: 'Rodzaj finansowania', property: 'financingType', group: ['all', 'vehicle', 'finance'], columnPage: [1]},
        {name: 'Doprowadzający', property: 'clientLeader', group: ['all', 'vehicle', 'finance'], columnPage: [1, 3]},

        {name: 'Samochód/Przedmiot', property: 'vehicleStatus', group: ['all', 'vehicle', 'finance'], columnPage: [2]},
        {name: 'Finansowanie', property: 'financeStatus', group: ['all', 'vehicle', 'finance'], columnPage: [2]},
        {name: 'Oferta polisy', property: 'policyOfferStatus', group: ['all', 'vehicle', 'finance'], columnPage: [2]},
        {
            name: 'Rejestracja/Płatność',
            property: 'registrationStatus',
            group: ['all', 'vehicle', 'finance'],
            columnPage: [2]
        },
        {name: 'Polisa', property: 'policyStatus', group: ['all', 'vehicle', 'finance'], columnPage: [2]},

        {name: 'Ofertę przygotował', property: 'offerOwner', group: ['all', 'vehicle', 'finance'], columnPage: [3]},
        {name: 'Kontakt z klientem', property: 'contactOwner', type: 'userChoiceFilter', group: ['all', 'vehicle', 'finance'], columnPage: [3]},
        {
            name: 'Prognozowany zysk Polisa',
            property: 'expectedProfit',
            group: ['all', 'vehicle', 'finance'],
            columnPage: [3],
            hidden: !isCoOwner
        },

        {
            name: 'Status zmieniony',
            property: 'statusChanged',
            group: ['all', 'vehicle', 'finance'],
            columnPage: [1, 2, 3]
        },
        {name: 'Wznowienie', property: 'duplicate', group: ['all', 'vehicle', 'finance'], columnPage: [1, 2, 3]},
        {name: 'Dni do końca', property: 'policyEnd', group: ['all', 'vehicle', 'finance'], columnPage: [1, 2, 3]},

        // POLICY
        {
            name: 'Imię i nazwisko/Firma',
            property: 'name',
            relationProperty: 'value',
            group: ['policy'],
            columnPage: [1, 2]
        },
        {name: 'Marka', property: 'vehicleMark', group: ['policy'], columnPage: [1, 2]},
        {name: 'Model', property: 'vehicleModel', group: ['policy'], columnPage: [1, 2]},
        {name: 'Finansujący', property: 'institution', group: ['policy'], columnPage: [1]},
        {name: 'Rodzaj finansowania', property: 'financingType', group: ['policy'], columnPage: [1]},
        {name: 'Doprowadzający', property: 'clientLeader', group: ['policy'], columnPage: [1, 2]},

        {name: 'Oferta polisy', property: 'policyOfferStatus', group: ['policy'], columnPage: [1]},
        {name: 'Polisa', property: 'policyStatus', group: ['policy'], columnPage: [1]},

        {name: 'Ofertę przygotował', property: 'offerOwner', group: ['policy'], columnPage: [2]},
        {name: 'Kontakt z klientem', property: 'contactOwner', type: 'userChoiceFilter', group: ['policy'], columnPage: [2]},
        {
            name: 'Prognozowany zysk Polisa',
            property: 'expectedPolicyProfit',
            group: ['policy'],
            columnPage: [2],
            hidden: !isCoOwner
        },

        {name: 'Status zmieniony', property: 'statusChanged', group: ['policy'], columnPage: [1, 2]},
        {name: 'Wznowienie', property: 'duplicate', group: ['policy'], columnPage: [1, 2]},
        {name: 'Dni do końca', property: 'policyEnd', group: ['policy'], columnPage: [1, 2]},
    ];

    const callbackFunction = (pageChanged, rowsPerPageChanged, sortChanged, sortDirectionChanged, searchParamChanged, filters) => {
        setPage(pageChanged + 1);
        setRowsPerPage(rowsPerPageChanged);
        setSort(sortChanged);
        setSortDirection(sortDirectionChanged);
        setSearchParam(searchParamChanged);
        setFilters(filters);
    }

    function printLastStatus(lastStatusUpdate) {
        if (lastStatusUpdate === null) {
            return '-';
        }

        let currentDate = moment().format('YYYY-MM-DD');

        if (currentDate === lastStatusUpdate.date) {
            return (
                <>
                    <b>Dziś</b>&nbsp;{lastStatusUpdate.time}
                </>
            )
        } else {
            return (
                <>
                    {lastStatusUpdate.date}&nbsp;{lastStatusUpdate.time}
                </>
            )
        }
    }

    const cells = (element, page, groupAttr) => {
        if (category === 'all' || category === 'vehicle' || category === 'finance') {
            return (
                <>
                    {[1, 2, 3].includes(page) &&
                        <td style={{color: element.status ? 'red' : 'inherit'}}>{element.id}</td>}
                    {[1, 2, 3].includes(page) && <td>{element.name}</td>}
                    {[1, 2, 3].includes(page) && <td>{element.vehicleMark ?? '-'}</td>}
                    {[1, 2, 3].includes(page) && <td>{element.vehicleModel ?? '-'}</td>}
                    {[1, 2, 3].includes(page) && <td>{element.vehiclePriceGross ?? '-'}</td>}
                    {[1].includes(page) && <td>{element.vin ?? '-'}</td>}
                    {[1].includes(page) && <td>{element.institution ?? '-'}</td>}
                    {[1].includes(page) && <td>{element.financingType ?? '-'}</td>}
                    {[1, 3].includes(page) && <td>{element.clientLeader ?? '-'}</td>}

                    {[2].includes(page) && <td style={{
                        backgroundColor: vehicleStatusColor(element.vehicleStatus)
                    }}>{element.vehicleStatus ?? '-'}</td>}
                    {[2].includes(page) && <td style={{
                        backgroundColor: financeStatusColor(element.financeStatus)
                    }}>{element.financeStatus ?? '-'}</td>}
                    {[2].includes(page) && <td style={{
                        backgroundColor: policyOfferStatusColor(element.policyOfferStatus)
                    }}>{element.policyOfferStatus ?? '-'}</td>}
                    {[2].includes(page) && <td style={{
                        backgroundColor: registrationStatusColor(element.registrationStatus)
                    }}>{element.registrationStatus ?? '-'}</td>}
                    {[2].includes(page) && <td style={{
                        backgroundColor: policyStatusColor(element.policyStatus)
                    }}>{element.policyStatus ?? '-'}</td>}

                    {[3].includes(page) && <td>{element.offerOwner ?? '-'}</td>}
                    {[3].includes(page) && <td>{element.contactOwner ?? '-'}</td>}
                    {([3].includes(page) && isCoOwner) && <td>{element.expectedProfit ?? '-'}</td>}

                    {[1, 2, 3].includes(page) && <td>{printLastStatus(element.lastStatusUpdate)}</td>}
                    {[1, 2, 3].includes(page) && <td>
                        {element.duplicate ? (
                            <>
                                <StyledFontAwesomeIconWarning size="2x" icon={faCircle} fixedWidth/>
                            </>
                        ) : (
                            <>-</>
                        )}
                    </td>}
                    {[1, 2, 3].includes(page) && <td>{element.policyEnd}</td>}
                </>
            );
        }

        if (category === 'policy') {
            return (
                <>
                    {[1, 2].includes(page) &&
                        <td style={{color: element.status ? 'red' : 'inherit'}}>{element.id}</td>}
                    {[1, 2].includes(page) && <td>{element.name}</td>}
                    {[1, 2].includes(page) && <td>{element.vehicleMark ?? '-'}</td>}
                    {[1, 2].includes(page) && <td>{element.vehicleModel ?? '-'}</td>}
                    {[1].includes(page) && <td>{element.institution ?? '-'}</td>}
                    {[1].includes(page) && <td>{element.financingType ?? '-'}</td>}
                    {[1, 2].includes(page) && <td>{element.clientLeader ?? '-'}</td>}
                    {[1].includes(page) && <td style={{
                        backgroundColor: policyOfferStatusColor(element.policyOfferStatus)
                    }}>{element.policyOfferStatus ?? '-'}</td>}
                    {[1].includes(page) && <td style={{
                        backgroundColor: policyStatusColor(element.policyStatus)
                    }}>{element.policyStatus ?? '-'}</td>}

                    {[2].includes(page) && <td>{element.offerOwner ?? '-'}</td>}
                    {[2].includes(page) && <td>{element.contactOwner ?? '-'}</td>}
                    {([2].includes(page) && isCoOwner) && <td>{element.expectedPolicyProfit ?? '-'}</td>}

                    {[1, 2].includes(page) && <td>{printLastStatus(element.lastStatusUpdate)}</td>}
                    {[1, 2].includes(page) && <td>
                        {element.duplicate ? (
                            <>
                                <StyledFontAwesomeIconWarning size="2x" icon={faCircle} fixedWidth/>
                            </>
                        ) : (
                            <>-</>
                        )}
                    </td>}
                    {[1, 2].includes(page) && <td>{element.policyEnd}</td>}
                </>
            );
        }
    }

    const actions = (element) => {
        return (
            <>
                <StyledButton onClick={() => {
                    setOpenModal(element.id);
                }}>
                    <StyledFontAwesomeIcon size="2x" icon={faTrashAlt} fixedWidth/>
                </StyledButton>
                <StyledLink onClick={() => {
                    downloadPackage(token, 'toDo', element.id);
                }}>
                    <StyledFontAwesomeIcon size="2x" icon={faFileDownload} fixedWidth/>
                </StyledLink>
                <Link to={`/zadania/${element.id}`}>
                    <StyledFontAwesomeIcon size="2x" icon={faArrowRight} fixedWidth/>
                </Link>
            </>
        )
    }

    return (
        <>
            <List
                tableColumns={tableColumns}
                cells={cells}
                data={toDo}
                actions={actions}
                readonly={false}
                initialTotal={total}
                parentCallback={callbackFunction}
                search={true}
                defaultSort={defaultSort}
                defaultSortDirection={defaultSortDirection}
                groupAttr={category}
                enableColumnsPage={true}
            ></List>

            {openModal && <Modal closeModal={() => {
                setOpenModal(false)
            }}>
                <P>Czy na pewno chcesz usunąć zadanie?</P>

                <ModalButtonWrapper>
                    <Button onClick={() => {
                        axios.delete(`${apiUrl}/toDo/${openModal}`, {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            }
                        }).then(() => {
                            toast.success("Zadanie zostało usunięte.");
                        }).catch((e) => {
                            toast.error("Nie możesz usunać zadania.");
                        }).finally(() => {
                            setOpenModal(false);
                        });
                    }}>Tak</Button>
                    <Button inactive onClick={() => setOpenModal(false)}>NIE</Button>
                </ModalButtonWrapper>
            </Modal>}
        </>
    );
};
