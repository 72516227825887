import React, {useEffect, useState} from 'react';
import {Link, Redirect, useHistory} from "react-router-dom";
import {useSelector} from "react-redux";
import styled from "styled-components";
import {Button} from "../../components/Buttons";
import PanelContentWrapper from "../../components/PanelContentWrapper";
import {Table} from "../../components/Table";
import {StyledFontAwesomeIcon, StyledLink} from "../../components/ListComponents";
import {faArrowRight, faCheck, faEye, faFileDownload, faTimes, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import {Pagination} from "@material-ui/lab";
import {Modal} from "../../components/Modal";
import {ModalButtonWrapper, P} from "../../components/Modals";
import axios from "axios";
import {apiUrl} from "../api";
import {toast} from "react-toastify";
import {Select} from "../../components/Form";
import {List} from "../../components/List";
import {downloadPackage} from "../../helpers/file";

const TabWrapper = styled.div`
    padding: 0px 60px;
    display: grid;
    grid-template-columns: repeat(8, auto);
    grid-gap: 15px;
    margin-bottom: 20px;

    ${Button} {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        &:not(:last-child)::after {
            content: '';
            position: absolute;
            right: -15px;
            width: 15px;
            height: 1px;
            background-color: #827E7E;
        }
    }
`;
const TaskStyledPanelContentWrapper = styled(PanelContentWrapper)`
    padding: 0px;
`;
const StyledButton = styled.button`
    border: none;
    background: transparent;
    color: ${({theme: {colors: {red}}}) => red};;
    font-weight: 900;
    font-size: 1.6rem;
    top: 5px;
    right: 5px;
`;

const StatusWrapper = styled.div`
    margin: 10px 0;
    padding: 0px 60px;
`

export const Tasks = ({match}) => {
    const history = useHistory();
    const [activeTab, setActiveTab] = useState(1);
    const token = useSelector((store) => store.token);
    const [redirect] = useState(null);
    const [status, setStatus] = useState(match.params.status);

    //task
    const [toDoTasks, setToDoTasks] = useState([]);
    const [total, setTotal] = useState(0);
    const [sort, setSort] = useState("");
    const [sortDirection, setSortDirection] = useState("");
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [searchParam, setSearchParam] = useState("");
    const [toDoTaskModal, setToDoTaskModal] = useState(null);

    // custom tasks
    const [personalTasks, setPersonalTasks] = useState([]);
    const [totalPersonal, setTotalPersonal] = useState(0);
    const [sortPersonal, setSortPersonal] = useState("");
    const [sortDirectionPersonal, setSortDirectionPersonal] = useState("");
    const [pagePersonal, setPagePersonal] = useState(1);
    const [rowsPerPagePersonal, setRowsPerPagePersonal] = useState(10);
    const [searchParamPersonal, setSearchParamPersonal] = useState("");
    const [personalTaskModal, setPersonalTaskModal] = useState(null);

    //missing tasks
    const [toDoMissingTasks, setToDoMissingTasks] = useState([]);
    const [totalMissing, setTotalMissing] = useState(0);
    const [sortMissing, setSortMissing] = useState("");
    const [sortDirectionMissing, setSortDirectionMissing] = useState("");
    const [pageMissing, setPageMissing] = useState(1);
    const [rowsPerPageMissing, setRowsPerPageMissing] = useState(10);
    const [searchParamMissing, setSearchParamMissing] = useState("");
    const [toDoMissingTasksModal, setToDoMissingTasksModal] = useState(null);

    const loadData = () => {
        axios.get(`${apiUrl}/tasks/list`, {
            params: {
                page: page,
                rowsPerPage: rowsPerPage,
                sort: sort,
                sortDirection: sortDirection,
                search: searchParam,
                status: status
            },
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then(({data: {tasks, total}}) => {
            setToDoTasks(tasks);
            setTotal(total);
        })
    }

    const loadPersonalData = () => {
        axios.get(`${apiUrl}/personal/tasks/list`, {
            params: {
                page: pagePersonal,
                rowsPerPage: rowsPerPagePersonal,
                sort: sortPersonal,
                sortDirection: sortDirectionPersonal,
                search: searchParamPersonal,
                status: status
            },
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then(({data: {tasks, total}}) => {
            setPersonalTasks(tasks);
            setTotalPersonal(total)
        })
    }

    const loadMissingData = () => {
        axios.get(`${apiUrl}/tasks/list`, {
            params: {
                page: pageMissing,
                rowsPerPage: rowsPerPageMissing,
                sort: sortMissing,
                sortDirection: sortDirectionMissing,
                search: searchParamMissing,
                status: status,
                type: 0
            },
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then(({data: {tasks, total}}) => {
            setToDoMissingTasks(tasks);
            setTotalMissing(total);
        })
    }
    useEffect(() => {
        loadData();
    }, [token, status, page, rowsPerPage, sort, sortDirection, searchParam]);

    useEffect(() => {
        if (toDoTaskModal === false) {
            loadData();
        }
    }, [toDoTaskModal])

    useEffect(() => {
        loadPersonalData();
    }, [token, status, pagePersonal, rowsPerPagePersonal, sortPersonal, sortDirectionPersonal, searchParamPersonal]);

    useEffect(() => {
        if (personalTaskModal === false) {
            loadPersonalData();
        }
    }, [personalTaskModal])

    useEffect(() => {
        loadMissingData();
    }, [token, status, pageMissing, rowsPerPageMissing, sortMissing, sortDirectionMissing, searchParamMissing]);

    useEffect(() => {
        if (toDoMissingTasksModal === false) {
            loadMissingData();
        }
    }, [toDoMissingTasksModal])

    const tableColumns = [
        {name: 'Klient', property: 'clientName'},
        {name: 'Dla', property: 'userName'},
        {name: 'Nazwa zadania', property: 'description'},
        {name: 'Komentarze', property: 'commentsCount', relationProperty: 'value'},
        {name: 'Data', property: 'Data'}
    ];

    const callbackFunction = (pageChanged, rowsPerPageChanged, sortChanged, sortDirectionChanged, searchParamChanged) => {
        setPage(pageChanged + 1);
        setRowsPerPage(rowsPerPageChanged);
        setSort(sortChanged);
        setSortDirection(sortDirectionChanged);
        setSearchParam(searchParamChanged);
    }

    const deleteCallback = (element) => {
        setToDoTaskModal(element.id);
    }

    const cells = (element, page, groupAttr) => {
        return (
            <>
                <td>{element.clientName ?? '-'}</td>
                <td>{element.userName ?? '-'}</td>
                <td>{element.description}</td>
                <td>{element.commentsCount}</td>
                <td>{element.date}</td>
            </>
        );
    }

    const actions = (element) => {
        return (
            <>
                {element.status !== 0 && (
                    <>
                        {element.status === 1 ? (
                            <StyledButton onClick={() => {
                                setToDoTaskModal(element.id);
                            }}>
                                <StyledFontAwesomeIcon size="2x" icon={faTimes} fixedWidth/>
                            </StyledButton>
                        ) : (
                            <StyledButton>
                                <StyledFontAwesomeIcon size="2x" icon={faCheck} fixedWidth/>
                            </StyledButton>
                        )}
                    </>
                )}
                <StyledLink to={`/taski/zobacz/${element.id}`}>
                    <StyledFontAwesomeIcon size="2x" icon={faArrowRight} fixedWidth/>
                </StyledLink>
            </>
        )
    }

    const personalTableColumns = [
        {name: 'Klient', property: 'clientName'},
        {name: 'Dla', property: 'userName'},
        {name: 'Nazwa zadania', property: 'description'},
        {name: 'Komentarze', property: 'commentsCount', relationProperty: 'value'},
        {name: 'Data', property: 'Data'}
    ];

    const personalCallbackFunction = (pageChanged, rowsPerPageChanged, sortChanged, sortDirectionChanged, searchParamChanged) => {
        setPagePersonal(pageChanged + 1);
        setRowsPerPagePersonal(rowsPerPageChanged);
        setSortPersonal(sortChanged);
        setSortDirectionPersonal(sortDirectionChanged);
        setSearchParamPersonal(searchParamChanged);
    }

    const personalDeleteCallback = (element) => {
        setPersonalTaskModal(element.id);
    }

    const personalCells = (element, page) => {
        return (
            <>
                <td>{element.clientName ?? '-'}</td>
                <td>{element.userName ?? '-'}</td>
                <td>{element.description}</td>
                <td>{element.commentsCount}</td>
                <td>{element.date}</td>
            </>
        );
    }

    const personalActions = (element) => {
        return (
            <>
                {element.status !== 0 && (
                    <>
                        {element.status === 1 ? (
                            <StyledButton onClick={() => {
                                setPersonalTaskModal(element.id);
                            }}>
                                <StyledFontAwesomeIcon size="2x" icon={faTimes} fixedWidth/>
                            </StyledButton>
                        ) : (
                            <StyledButton>
                                <StyledFontAwesomeIcon size="2x" icon={faCheck} fixedWidth/>
                            </StyledButton>
                        )}
                    </>
                )}
                <StyledLink to={`/taski/osobiste/zobacz/${element.id}`}>
                    <StyledFontAwesomeIcon size="2x" icon={faArrowRight} fixedWidth/>
                </StyledLink>
            </>
        )
    }

    const missingTableColumns = [
        {name: 'Klient', property: 'clientName'},
        {name: 'Dla', property: 'userName'},
        {name: 'Nazwa zadania', property: 'description'},
        {name: 'Komentarze', property: 'commentsCount', relationProperty: 'value'},
        {name: 'Data', property: 'Data'}
    ];

    const missingCallbackFunction = (pageChanged, rowsPerPageChanged, sortChanged, sortDirectionChanged, searchParamChanged) => {
        setPageMissing(pageChanged + 1);
        setRowsPerPageMissing(rowsPerPageChanged);
        setSortMissing(sortChanged);
        setSortDirectionMissing(sortDirectionChanged);
        setSearchParamMissing(searchParamChanged);
    }

    const missingDeleteCallback = (element) => {
        setToDoMissingTasksModal(element.id);
    }

    const missingCells = (element, page) => {
        return (
            <>
                <td>{element.clientName ?? '-'}</td>
                <td>{element.userName ?? '-'}</td>
                <td>{element.description}</td>
                <td>{element.commentsCount}</td>
                <td>{element.date}</td>
            </>
        );
    }

    const missingActions = (element) => {
        return (
            <>
                {element.status !== 0 && (
                    <>
                        {element.status === 1 ? (
                            <StyledButton onClick={() => {
                                setToDoMissingTasksModal(element.id);
                            }}>
                                <StyledFontAwesomeIcon size="2x" icon={faTimes} fixedWidth/>
                            </StyledButton>
                        ) : (
                            <StyledButton>
                                <StyledFontAwesomeIcon size="2x" icon={faCheck} fixedWidth/>
                            </StyledButton>
                        )}
                    </>
                )}
                <StyledLink to={`/taski/zobacz/${element.id}`}>
                    <StyledFontAwesomeIcon size="2x" icon={faArrowRight} fixedWidth/>
                </StyledLink>
            </>
        )
    }

    return (
        <>
            <TaskStyledPanelContentWrapper>
                <StatusWrapper>
                    <span>Status:</span>
                    <Select onChange={(e) => {
                        setStatus(e.target.value)
                    }}>
                        <option selected={status === 0} value={0}>Nowe</option>
                        <option selected={status === 1} value={1}>W trakcie</option>
                        <option selected={status === 2} value={2}>Zakończone</option>
                    </Select>
                </StatusWrapper>
                <TabWrapper>
                    <Button type="button" inactive={activeTab !== 1} onClick={() => {
                        setActiveTab(1)
                    }}>Zadania</Button>
                    <Button type="button" inactive={activeTab !== 2} onClick={() => {
                        setActiveTab(2)
                    }}>Zadania customowe</Button>
                    <Button type="button" inactive={activeTab !== 3} onClick={() => {
                        setActiveTab(3)
                    }}>Braki TODO</Button>
                </TabWrapper>

                {activeTab === 1 && (
                    <>
                        <List
                            tableColumns={tableColumns}
                            cells={cells}
                            data={toDoTasks}
                            actions={actions}
                            readonly={false}
                            initialTotal={total}
                            parentCallback={callbackFunction}
                            deleteCallback={deleteCallback}
                        ></List>
                    </>
                )}

                {activeTab === 2 && (
                    <>
                        <List
                            tableColumns={personalTableColumns}
                            cells={personalCells}
                            data={personalTasks}
                            actions={personalActions}
                            readonly={false}
                            initialTotal={totalPersonal}
                            parentCallback={personalCallbackFunction}
                            deleteCallback={personalDeleteCallback}
                        ></List>
                    </>
                )}

                {activeTab === 3 && (
                    <>
                        <List
                            tableColumns={missingTableColumns}
                            cells={missingCells}
                            data={toDoMissingTasks}
                            actions={missingActions}
                            readonly={false}
                            initialTotal={totalMissing}
                            parentCallback={missingCallbackFunction}
                            deleteCallback={missingDeleteCallback}
                        ></List>
                    </>
                )}
            </TaskStyledPanelContentWrapper>

            {toDoTaskModal && <Modal closeModal={() => {
                setToDoTaskModal(false)
            }}>
                <P>Czy na pewno chcesz zakończyć zadanie?</P>

                <ModalButtonWrapper>
                    <Button onClick={() => {
                        axios.put(`${apiUrl}/tasks/${toDoTaskModal}`, {}, {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            }
                        }).then(() => {
                            toast.success("Zadanie zostało zakończone.");
                        }).catch((e) => {
                            toast.error("Nie możesz zatwierdzić zadania.");
                        }).finally(() => {
                            setToDoTaskModal(false);
                        });
                    }}>Tak</Button>
                    <Button inactive onClick={() => setToDoTaskModal(null)}>NIE</Button>
                </ModalButtonWrapper>
            </Modal>}

            {personalTaskModal && <Modal closeModal={() => {
                setPersonalTaskModal(false)
            }}>
                <P>Czy na pewno chcesz zakończyć zadanie?</P>

                <ModalButtonWrapper>
                    <Button onClick={() => {
                        axios.put(`${apiUrl}/personal/tasks/${personalTaskModal}`, {}, {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            }
                        }).then(() => {
                            toast.success("Zadanie zostało zakończone.");
                        }).catch((e) => {
                            toast.error("Nie możesz zatwierdzić zadania.");
                        }).finally(() => {
                            setPersonalTaskModal(false);
                        });
                    }}>Tak</Button>
                    <Button inactive onClick={() => setPersonalTaskModal(null)}>NIE</Button>
                </ModalButtonWrapper>
            </Modal>}

            {toDoMissingTasksModal && <Modal closeModal={() => {
                setToDoMissingTasksModal(false)
            }}>
                <P>Czy na pewno chcesz zakończyć zadanie?</P>

                <ModalButtonWrapper>
                    <Button onClick={() => {
                        axios.put(`${apiUrl}/tasks/${toDoMissingTasksModal}`, {}, {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            }
                        }).then(() => {
                            toast.success("Zadanie zostało zakończone.");
                        }).catch((e) => {
                            toast.error("Nie możesz zatwierdzić zadania.");
                        }).finally(() => {
                            setToDoMissingTasksModal(false);
                        });
                    }}>Tak</Button>
                    <Button inactive onClick={() => setToDoMissingTasksModal(null)}>NIE</Button>
                </ModalButtonWrapper>
            </Modal>}
        </>
    );
}
