import {InputSearch, Table} from "./Table";
import {
    ListHeader, ListHeaderWithoutSort, StyledFontAwesomeIconWhite,
    StyledFontAwesomeSortIcon,
    StyledPanelContentWrapper
} from "./ListComponents";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {
    faAngleDoubleLeft, faAngleDoubleRight,
    faChevronDown,
    faChevronUp,
    faSort,
    faSortDown,
    faSortUp, faTimes
} from "@fortawesome/free-solid-svg-icons";
import {TablePagination} from "@material-ui/core";
import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Label, Switch} from "./Form";
import {Button} from "./Buttons";
import axios from "axios";
import {apiUrl} from "../views/api";

const FilterButton = styled.button`
    background: none;
    border: none;
    font-weight: 600;
`;

export const FilterIcon = styled(FontAwesomeIcon)`
    color: ${({theme: {colors: {red}}}) => red};
    font-size: 1.5rem;
    margin-left: 3px;
`;

const FilterPanel = styled.div`
    width: 100%;
`;

const FilterElementsContainer = styled.div`
    margin-top: 10px;
    display: grid;
    max-height: 105px;
    grid-auto-flow: column;
    grid-template-columns: repeat(auto-fill, 210px);
    grid-template-rows: repeat(auto-fill, 50px);
`;

const FilterElementContainer = styled.div`
    display: flex;
    align-items: center;
    height: 50px;
`;

const FilterElement = styled.div`
    height: 50px;
    width: 200px;
`;

const FilterButtonsContainer = styled.div`
    margin-top: 5px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
`;

const FilterWrapper = styled.div`
    margin-bottom: 20px;
`;

const FilterLabel = styled(Label)`
    margin-right: 5px;
`;

const PageButtons = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 10px;
`;

export const FilterSelect = styled.select`
    font-size: 1.2rem;
    border: none;
    padding: 5px 8px;
    margin-top: 5px;
    width: 200px;
`;

export const StyledSwitch = styled(Switch)`
    margin-top: 5px;
`;

export const StyledFontAwesomeColumnsPageIcon = styled(FontAwesomeIcon)`
    font-size: 2rem;
    cursor: pointer;
`;

export const ColumnsPageInfo = styled.span`
    font-size: 1rem;
    margin: 0px 10px;
    padding-top: 4px;
    cursor: pointer;
`;

export const List = ({
                         tableColumns,
                         actions,
                         data,
                         cells,
                         initialTotal,
                         readonly,
                         parentCallback,
                         disableMargin,
                         search = false,
                         defaultSort = '',
                         defaultSortDirection = '',
                         enableColumnsPage = false,
                         groupAttr = null
                     }) => {
    const token = useSelector((store) => store.token);
    const [total, setTotal] = useState(null);
    const [sort, setSort] = useState(defaultSort);
    const [sortDirection, setSortDirection] = useState(defaultSortDirection);
    const [filters, setFilters] = useState({});
    const [initialFilters, setInitialFilters] = useState({});
    const [filtersToggle, setFiltersToggle] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [searchParam, setSearchParam] = useState("");
    const [columnsPage, setColumnsPage] = useState(1);
    const [maxColumnsPage, setMaxColumnsPage] = useState(1);
    const [users, setUsers] = useState([]);
    let timeout = 0;

    const searchRecords = (e) => {
        let searchText = e.target.value;
        if (timeout) clearTimeout(timeout);
        timeout = setTimeout(() => {
            setSearchParam(searchText);
        }, 500);
    }
    const sendData = () => {
        parentCallback(page, rowsPerPage, sort, sortDirection, searchParam, filters);
    }

    function calculateMaxPage() {
        let maxPage = 1;
        if (groupAttr !== null) {
            tableColumns.forEach((el) => {
                if ((el.group === undefined || el.group.includes(groupAttr)) && el.columnPage !== undefined) {
                    const columnMax = Math.max(...el.columnPage);
                    maxPage = columnMax > maxPage ? columnMax : maxPage;
                }
            })
        } else {
            tableColumns.forEach((el) => {
                if (el.columnPage !== undefined) {
                    const columnMax = Math.max(...el.columnPage);
                    maxPage = columnMax > maxPage ? columnMax : maxPage;
                }
            })
        }

        setMaxColumnsPage(maxPage);
    }

    useEffect(() => {
        axios.get(`${apiUrl}/user/all`, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then(({data: {users: newUsers}}) => {
            setUsers(newUsers);
        });

        let initialFiltersTemp = {};
        tableColumns.map((element) => {
            if (element.group === undefined || element.group.includes(groupAttr)) {
                if (element.type === 'choiceFilter' || element.type === 'userChoiceFilter') {
                    if (element.hasOwnProperty('relationProperty')) {
                        initialFiltersTemp = {
                            ...initialFiltersTemp,
                            [element.property + '_' + element.relationProperty]: ''
                        };
                    } else {
                        initialFiltersTemp = {
                            ...initialFiltersTemp,
                            [element.property]: ''
                        };
                    }
                }
            }
        });

        calculateMaxPage();
        setFilters(initialFiltersTemp);
        setInitialFilters(initialFiltersTemp);
    }, []);

    useEffect(() => {
        sendData();
    }, [sort, sortDirection, searchParam, page, rowsPerPage, total, filters]);

    const enableFilters = () => {
        return tableColumns.filter(element => {
            return (element.group === undefined || element.group.includes(groupAttr)) && element.type === 'choiceFilter';
        }).length > 0;
    }

    const printChoiceFilter = (element) => {
        return (
            <>
                <FilterElementContainer>
                    <FilterElement>
                        <FilterLabel htmlFor={element.property}>{element.name}</FilterLabel>
                        <FilterSelect id={element.property}
                                      value={element.hasOwnProperty('relationProperty') ? filters[element.property + '.' + element.relationProperty] : filters[element.property]}
                                      onChange={e => {
                                          var key = element.hasOwnProperty('relationProperty') ? element.property + '_' + element.relationProperty : element.property;

                                          setFilters({
                                              ...filters,
                                              [key]: e.target.value
                                          });
                                      }}>
                            <option value="">Wybierz...</option>
                            {element.filterValues.map((optionValue) => <option
                                value={optionValue.value}>{optionValue.label}</option>)}
                        </FilterSelect>
                    </FilterElement>
                </FilterElementContainer>
            </>
        );
    }

    const printUserChoiceFilter = (element) => {
        return (
            <>
                <FilterElementContainer>
                    <FilterElement>
                        <FilterLabel htmlFor={element.property}>{element.name}</FilterLabel>
                        <FilterSelect id={element.property}
                                      value={element.hasOwnProperty('relationProperty') ? filters[element.property + '.' + element.relationProperty] : filters[element.property]}
                                      onChange={e => {
                                          var key = element.hasOwnProperty('relationProperty') ? element.property + '_' + element.relationProperty : element.property;

                                          setFilters({
                                              ...filters,
                                              [key]: e.target.value
                                          });
                                      }}>
                            <option value="">Wybierz...</option>
                            {users.map((user) => (
                                <option value={user.name}>{user.name}</option>
                            ))}
                        </FilterSelect>
                    </FilterElement>
                </FilterElementContainer>
            </>
        );
    }

    const printSwitchFilter = (element) => {
        return (
            <>
                <FilterElementContainer>
                    <FilterElement>
                        <FilterLabel htmlFor={element.property}>{element.name}</FilterLabel>
                        <StyledSwitch
                            checked={element.hasOwnProperty('relationProperty') ? filters[element.property + '.' + element.relationProperty] : filters[element.property] ?? false}
                            id={element.property}
                            onChange={(e) => {
                                var key = element.hasOwnProperty('relationProperty') ? element.property + '_' + element.relationProperty : element.property;

                                setFilters({
                                    ...filters,
                                    [key]: e.target.checked
                                });
                            }}/>
                    </FilterElement>
                </FilterElementContainer>
            </>
        );
    }

    const changeSort = (property) => {
        if (property === sort) {
            if (sortDirection === 'desc') {
                setSortDirection('asc');
            } else {
                setSort('');
                setSortDirection('');
            }
        } else {
            setSort(property);
            setSortDirection('desc');
        }
    };

    const printHeader = (value, property, type = '') => {
        if (type === 'sort') {
            return (
                <>
                    <ListHeader onClick={() => {
                        changeSort(property);
                    }}>{value}
                        {sort === property && sortDirection === 'asc' && (
                            <StyledFontAwesomeSortIcon icon={faSortUp}/>)}
                        {sort === property && sortDirection === 'desc' && (
                            <StyledFontAwesomeSortIcon icon={faSortDown}/>)}
                        {(sort !== property || (sort === property && sortDirection === '')) && (
                            <StyledFontAwesomeSortIcon icon={faSort}/>)}
                    </ListHeader>
                </>);
        }

        return (<>
            <ListHeaderWithoutSort>{value}</ListHeaderWithoutSort>
        </>);
    };

    return (
        <>
            {search && (
                <>
                    <div>
                        <InputSearch onChange={(e) => {
                            searchRecords(e);
                        }}/>
                    </div>
                </>
            )}


            <StyledPanelContentWrapper style={
                {
                    'margin': disableMargin ? '40px 0px 0px 0px' : '40px 20px 20px',
                    'padding': disableMargin ? '0px' : '20px 30px',
                    'border-radius': disableMargin ? '0px' : '20px'
                }
            }>
                {enableFilters() === true && (
                    <>
                        <FilterWrapper>
                            <FilterButton onClick={() => {
                                setFiltersToggle(!filtersToggle);
                            }}>Filtry<FilterIcon icon={filtersToggle ? faChevronUp : faChevronDown}/></FilterButton>
                            <FilterPanel style={{display: filtersToggle ? "block" : "none"}}>
                                <FilterElementsContainer>
                                    {tableColumns.map((value, index) => {
                                        if (value.group === undefined || value.group.includes(groupAttr)) {
                                            if (value.type === 'choiceFilter') {
                                                return printChoiceFilter(value);
                                            }

                                            if (value.type === 'userChoiceFilter') {
                                                return printUserChoiceFilter(value);
                                            }

                                            if (value.type === 'switchFilter') {
                                                return printSwitchFilter(value);
                                            }
                                        }
                                    })}
                                </FilterElementsContainer>
                                <FilterButtonsContainer>
                                    <Button small smallText onClick={() => {
                                        setFilters(initialFilters);
                                    }}><StyledFontAwesomeIconWhite style={{fontSize: '1rem', marginRight: '5px'}}
                                                                   icon={faTimes}/> Wyczyść filtry</Button>
                                </FilterButtonsContainer>
                            </FilterPanel>
                        </FilterWrapper>
                    </>
                )}
                {enableColumnsPage && (
                    <>
                        <PageButtons>
                            <StyledFontAwesomeColumnsPageIcon icon={faAngleDoubleLeft}
                                                              style={{color: columnsPage > 1 ? 'red' : 'grey'}}
                                                              onClick={() => {
                                                                  if (columnsPage > 1) {
                                                                      setColumnsPage(columnsPage - 1);
                                                                  }
                                                              }}
                            />
                            <ColumnsPageInfo>{columnsPage} of {maxColumnsPage}</ColumnsPageInfo>
                            <StyledFontAwesomeColumnsPageIcon icon={faAngleDoubleRight}
                                                              style={{color: columnsPage < maxColumnsPage ? 'red' : 'grey'}}
                                                              onClick={() => {
                                                                  if (columnsPage < maxColumnsPage) {
                                                                      setColumnsPage(columnsPage + 1);
                                                                  }
                                                              }}/>
                        </PageButtons>
                    </>
                )}

                <Table>
                    <thead>
                    <tr>
                        {tableColumns.map(({name, property, type, columnPage, filterOnly, group, hidden}, key) => {
                            if (filterOnly) {
                                return;
                            }

                            if (hidden) {
                                return;
                            }

                            if (group !== undefined && !group.includes(groupAttr)) {
                                return;
                            }

                            if (enableColumnsPage) {
                                if (columnPage === undefined || columnPage.includes(columnsPage)) {
                                    return (
                                        printHeader(name, property, type)
                                    )
                                }
                            } else {
                                return (
                                    printHeader(name, property, type)
                                )
                            }
                        })}
                        <th style={{width: "150px"}}/>
                    </tr>
                    </thead>
                    <tbody>
                    {data.map((element) => (
                        <tr>
                            {cells(element, columnsPage, groupAttr)}
                            <td style={{display: "flex", width: "150px"}}>
                                {actions(element)}
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
                <TablePagination
                    labelRowsPerPage={'Pokaż na stronie'}
                    component="div"
                    count={total ?? initialTotal}
                    page={page ?? 0}
                    onPageChange={(event, newPage) => {
                        setPage(newPage);
                    }}
                    rowsPerPage={rowsPerPage ?? 25}
                    onRowsPerPageChange={(event) => {
                        setRowsPerPage(event.target.value);
                    }}
                />
            </StyledPanelContentWrapper>
        </>
    );
}