const vehicleStatus = {
    'Poszukiwanie': 'inherit',
    'Produkcja': 'inherit',
    'Propozycja (dla klienta)': 'inherit',
    'Akceptacja (stock)': '#84E4AE',
    'Samochód obcy': '#84E4AE'
};

export const vehicleStatusColor = (status) => {
    if (Object.keys(vehicleStatus).includes(status)) {
        return vehicleStatus[status];
    }

    return 'inherit';
}

const financeStatus = {
    'Kalkulacja': 'inherit',
    'Propozycja (dla klienta)': 'inherit',
    'Akceptacja propozycji': 'inherit',
    'Przygotowanie wniosku': 'inherit',
    'Wysyłanie wniosku': 'inherit',
    'W decyzji': '#FFDA85',
    'Zgoda': '#84E4AE',
    'Zgoda warunkowa': '#FFDA85',
    'Odmowa': '#FF9876',
    'Umowa do podpisania': '#DBF3FF',
    'Umówiono podpisanie': '#DBF3FF',
    'Podpisana umowa': '#84E4AE'
};

export const financeStatusColor = (status) => {
    if (Object.keys(financeStatus).includes(status)) {
        return financeStatus[status];
    }

    return 'inherit';
}

const policyOfferStatus = {
    'Przypisany kontakt': 'inherit',
    'Przygotowanie oferty': 'inherit',
    'Gotowa oferta': 'inherit',
    'Kontakt z klientem': '#DBF3FF',
    'Wsparcie': '#FFDA85',
    'Akceptacja': '#84E4AE',
    'Rezygnacja': '#FF9876',
    'Wniosek do leasingu': '#DBF3FF',
    'Odmowa leasingu': '#FF9876',
    'Akceptacja leasingu': '#84E4AE'
};

export const policyOfferStatusColor = (status) => {
    if (Object.keys(policyOfferStatus).includes(status)) {
        return policyOfferStatus[status];
    }

    return 'inherit';
}

const gapStatus = {
    'Oferta': '#FFDA85',
    'Kontakt z klientem': '#DBF3FF',
    'Akceptacja': '#84E4AE',
    'Rezygnacja': '#FF9876'
};

export const gapStatusColor = (status) => {
    if (Object.keys(gapStatus).includes(status)) {
        return gapStatus[status];
    }

    return 'inherit';
}

const registrationStatus = {
    'Wpłata OW': 'inherit',
    'Zamówienie FV': 'inherit',
    'Faktura': '#DBF3FF',
    'Dokumenty do rejestracji': '#DBF3FF',
    'W trakcie rejestracji': '#FFDA85',
    'Zarejestrowano': '#84E4AE'
};

export const registrationStatusColor = (status) => {
    if (Object.keys(registrationStatus).includes(status)) {
        return registrationStatus[status];
    }

    return 'inherit';
}

const policyStatus = {
    'W trakcie': '#DBF3FF',
    'Wystawiona': '#84E4AE',
    'Wystawiona obca': '#FF9876'
};

export const policyStatusColor = (status) => {
    if (Object.keys(policyStatus).includes(status)) {
        return policyStatus[status];
    }

    return 'inherit';
}

