import React, {useState} from 'react';
import styled from "styled-components";
import {Button} from "../../components/Buttons";
import {ToDoListTable} from "./ToDoListTable";
import PanelContentWrapper from "../../components/PanelContentWrapper";

const TabWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(4, auto);
    grid-gap: 15px;
    margin-bottom: 20px;

    ${Button} {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;

const StyledPanelContentWrapper = styled(PanelContentWrapper)`
    padding: 30px 0px;
`;

const StyledPanelButtonWrapper = styled(PanelContentWrapper)`
    padding: 30px 30px;
    background: inherit;
    max-height: 80px;
`;

export const ToDoList = ({archived}) => {
    const [activeTab, setActiveTab] = useState(1);

    return (
        <>
        <StyledPanelButtonWrapper>
            <TabWrapper>
                <Button type="button" inactive={activeTab !== 1} onClick={() => {
                    setActiveTab(1)
                }}>Wszystkie</Button>
                <Button type="button" inactive={activeTab !== 2} onClick={() => {
                    setActiveTab(2)
                }}>Samochód</Button>
                <Button type="button" inactive={activeTab !== 3} onClick={() => {
                    setActiveTab(3)
                }}>Finansowanie</Button>
                <Button type="button" inactive={activeTab !== 4} onClick={() => {
                    setActiveTab(4)
                }}>Polisy</Button>
            </TabWrapper>
        </StyledPanelButtonWrapper>

        <StyledPanelContentWrapper>
            {activeTab === 1 && <>
                <ToDoListTable archived={archived} category={'all'} defaultSort={'created_at'} defaultSortDirection={'desc'}/>
            </>}

            {activeTab === 2 && <>
                <ToDoListTable archived={archived} category={'vehicle'} defaultSort={'created_at'} defaultSortDirection={'desc'}/>
            </>}

            {activeTab === 3 && <>
                <ToDoListTable archived={archived} category={'finance'} defaultSort={'created_at'} defaultSortDirection={'desc'}/>
            </>}

            {activeTab === 4 && <>
                <ToDoListTable archived={archived} category={'policy'} defaultSort={'parentPolicyEndDate'} defaultSortDirection={'asc'}/>
            </>}
        </StyledPanelContentWrapper>
</>
)
    ;
};
